<template>
    <div
            id="kt_header_mobile"
            class="header-mobile align-items-center"
            v-bind:class="headerClasses"
    >
        <!--begin::Logo-->
        <a href="/">
            <img :src="logo_path" alt="Yelko Education App" v-if="logo_path!=null" style="width: auto; height: 55px"/>
            <img src="media/logos/yeldemy_logo.png" alt="Yelko Education App"  v-else style="width: auto; height: 55px"/>
        </a>
        <!--end::Logo-->
        <!--begin::Toolbar-->
        <div class="d-flex align-items-center">
            <!--begin::Aside Mobile Toggle-->
            <button
                    v-if="asideEnabled"
                    class="btn p-0 burger-icon burger-icon-left"
                    id="kt_aside_mobile_toggle"
            >
                <span></span>
            </button>
            <!--end::Aside Mobile Toggle-->
            <!--begin::Header Menu Mobile Toggle-->
            <button
                    class="btn p-0 burger-icon ml-4"
                    id="kt_header_mobile_toggle"
                    ref="kt_header_mobile_toggle"
            >
                <span></span>
            </button>
            <!--end::Header Menu Mobile Toggle-->
            <!--begin::Topbar Mobile Toggle-->
            <button
                    class="btn btn-hover-text-primary p-0 ml-2 bg-success rounded"
                    id="kt_header_mobile_topbar_toggle"
                    ref="kt_header_mobile_topbar_toggle"
                    style="padding: 10px !important;" >
        <span class="svg-icon svg-icon-xl">
          <!--begin::Svg Icon | path:svg/icons/General/User.svg-->
            <span v-if="currentUser.first_name" class="symbol-label font-size-h5 text-white   ml-2font-weight-bold">
          {{ currentUser.first_name.charAt(0).toUpperCase() }}
        </span>
            <!--end::Svg Icon-->
        </span>
            </button>
            <!--end::Topbar Mobile Toggle-->
        </div>
        <!--end::Toolbar-->
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import KTLayoutHeaderTopbar from "@/assets/js/layout/base/header-topbar.js";
    import {ColorsVariableMixin} from "@/mixins/ColorsVariableMixin";

    export default {
        name: "KTHeaderMobile",
        mixins:[ColorsVariableMixin],
        created() {
            this.$bus.on('initSetting', ()=>{
                this.getColors();
            })
        },
        components: {},
        mounted() {
            // Init Header Topbar For Mobile Mode
            KTLayoutHeaderTopbar.init(this.$refs["kt_header_mobile_topbar_toggle"]);
            this.getColors();
        },
        computed: {
            currentUser() {
                return this.$store.getters.currentUser;
            },
            ...mapGetters(["layoutConfig", "getClasses"]),

            /**
             * Get header logo
             * @returns {string}
             */
            headerLogo() {
                return process.env.BASE_URL + this.layoutConfig("self.logo.dark");
            },

            /**
             * Get classes for mobile header
             * @returns {null|*}
             */
            headerClasses() {
                const classes = this.getClasses("header_mobile");
                if (typeof classes !== "undefined") {
                    return classes.join(" ");
                }
                return null;
            },

            /**
             * Check if the left aside menu is enabled
             * @returns {boolean}
             */
            asideEnabled() {
                return !!this.layoutConfig("aside.self.display");
            }
        }
    };
</script>
<style scoped>

        .header-mobile-fixed .header-mobile {
        background-color: #004b98;
        }


</style>