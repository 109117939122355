import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class StudentParentsVisitLogsService {
    #api = null;

    constructor() {
        this.#api = `${API_URL}user/student-parents-visit-log`;
    }

    paginate(data={},index = null) {
        let url = `${this.#api}`;
        if (index != null)
                url = `${url}?page=${index}`;
        let param ={
                params: data
        }
        return apiService.query(url,param);
    }

    getByStudent(data={},index = null, studentId) {
        let url = `${this.#api}/get-by-student/${studentId}`;
        if (index != null)
                url = `${url}?page=${index}`;
        let param ={
                params: data
        }
        return apiService.query(url,param);
    }

    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url, data);
    }

    markAsAttended(id, data) {
        let url = `${this.#api}/${id}/mark-as-attended`
        return apiService.put(url, data);
    }

    markAsNotAttended(id, data) {
        let url = `${this.#api}/${id}/mark-as-not-attended`
        return apiService.put(url, data);
    }

    store(data) {
        let url = `${this.#api}`
        return apiService.post(url, data);
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url);
    }

    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }

    all() {
        let url = `${this.#api}/get/all`
        return apiService.get(url);
    }

    getRequestedMeetingCount(){
        let url = `${this.#api}/get/requested-count`
        return apiService.get(url);
    }
}
