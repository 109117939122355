<template>
    <div
        class="d-flex flex-column pt-4 bgi-size-cover bgi-no-repeat rounded-top"
    >
      <h4 class="rounded-top border-bottom px-3 pb-2">
        <span class="">
          Notifications
        </span>
      </h4>
      <div v-if="notifications.length > 0">
        <perfect-scrollbar
            class="navi scroll my-4"
            style="max-height: 40vh; position: relative;"
        >
          <template v-for="(item, i) in notifications">
            <a @click.prevent="viewNotification(item)" class="navi-item cursor-pointer m-2" v-bind:key="i" v-bind:class="{ read: !item.is_viewed }">
              <div class="navi-link">
                <div class="navi-icon">
                  <div
                      class="symbol symbol-40"
                      v-bind:class="`symbol-light-${
                item.status == 'pending'
                  ? 'warning'
                  : item.status == 'ready'
                  ? 'primary'
                  : 'success'
              }`"
                  >
                  <span class="symbol-label">
                    <span
                        class="svg-icon svg-icon-lg"
                        v-bind:class="`symbol-light-${
                        item.status == 'pending'
                          ? 'warning'
                          : item.status == 'ready'
                          ? 'primary'
                          : 'success'
                      }`"
                    >
                    <i v-bind:class="`fas ${checkNotificationType(item)}`"></i>
                    </span>
                  </span>
                  </div>
                </div>
                <div class="navi-text ml-7">
                  <div class="font-weight-bold">
                    {{ item.title }}
                  </div>
                  <div class="text-muted">
                    {{item.formatted_date_sent}} {{item.formatted_time}} {{ item.status }}
                  </div>
                </div>
              </div>
            </a>
          </template>
        </perfect-scrollbar>
      </div>
      <div v-if="notifications.length == 0"
          class="d-flex flex-center item- font-weight-bold m-3"
      >
          <h5>No notification found</h5>
      </div>
      <div
          class="d-flex flex-center item- font-weight-bold"
      >
        <router-link
            :to="{ name: 'all-notification' }"
            class="text-dark text-hover-primary font-size-lg mb-2"
        >
          View All
        </router-link>
      </div>
    </div>
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>
import NotificationService from "@/core/services/notification/NotificationService";
import {API_URL} from '../../../../common/config'

const notificationService = new NotificationService();
export default {
  name: "KTDropdownNotification",
  data() {
    return {
      tabIndex: 0,
      is_loading: false,
      attrs: false,
      notifications: []
    };
  },
  mounted() {
    // this.getNotifications()
  },
  created() {
    this.$bus.on('checkNotification', () => {
      this.getNotifications()
    })
  },
  methods: {
    getNotifications() {
      this.is_loading = true
      notificationService.getByUser(this.currentUser.id).then((response) => {
        this.is_loading = false
        this.notifications = response.data.data;
      }).catch(error => {
        this.is_loading = false
      })
    },
    viewNotification(notification) {
      notification.is_viewed = true;
      notificationService.update(notification.id, notification).then((res) => {
        this.getNotifications();
      });
      if ((notification.type == "print_id_card"||notification.type == "result_certificate" || notification.type == "offer_letter" || notification.type == "admit_card") && notification.status == 'ready') {
        //window.open(`${API_URL}notification/view/${notification.id}`, "_blank");
        this.$router.push({
          name: "print-job-queue",
          params: {userNotificationId: notification.id}
        });
        this.getNotifications();
      } else {
        this.getNotifications();
      }
      if(notification.type=='export'){
        window.open(notification.url,'_blank');
      }
    },

    checkNotificationType(item) {
      if (item.type == 'print_id_card' || item.type == 'result_certificate' || item.type == 'offer_letter') {
        return 'fa-print';
      }
      if(item.type){
        if (item.type.includes('export')) {
          return 'fa-file-csv';
        }
      }

      return 'fa-info';
    },
  },

  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
  }
};
</script>

<style scoped>
.read {
  background-color: #e3e3e3;
}
.navi-item {
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, box-shadow 0.3s;
}
.navi .navi-item .navi-link {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  padding: 0.5rem 0.5rem !important;
}
.symbol {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.symbol-label {
  font-size: 1.5rem;
  color: #007bff;
}

/* Mobile View Adjustments */
@media (max-width: 768px) {
  .navi-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .navi-icon {
    margin-bottom: 8px;
  }

  .navi-text {
    margin-left: 0;
  }
}
</style>

