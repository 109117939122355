<template>
    <!-- begin:: Header Topbar -->
    <div class="topbar">
      <b-dropdown
        size="sm"
        variant="link"
        toggle-class="topbar-item text-decoration-none"
        no-caret
        right
        no-flip
      >
        <template v-slot:button-content>
          <div class="btn btn-icon  btn-lg btn-dropdown mr-1">
            <span @click="setFocus" ref="mySearch" class="svg-icon svg-icon-xl svg-icon-primary">
                  <i class="menu-icon fa fa-search mr-2"><span></span></i>
            </span>
          </div>
        </template>
        <b-dropdown-text tag="div" class="min-w-md-350px">
          <KTSearchDefault ref="search-focus" :triggerSearchBox="triggerSearchBox"></KTSearchDefault>
        </b-dropdown-text>
      </b-dropdown>

       <b-dropdown
          size="sm"
          variant="link"
          toggle-class="topbar-item text-decoration-none"
          no-caret
          right
          no-flip
        >
          <template v-slot:button-content >
            <div
              @click="callNotification()"
              class="btn btn-icon  btn-dropdown btn-lg mr-1 pulse pulse-primary"
            >
              <span class="svg-icon svg-icon-xl svg-icon-primary">
                <i class="menu-icon far fa-bell"><span></span></i>
              </span>
              <span v-if="liveNotificationCount > 0" class="count_number_badge_head">{{liveNotificationCount}}</span>
            </div>
          </template>
          <b-dropdown-text tag="div" class="min-w-md-400px">
            <KTDropdownNotification ></KTDropdownNotification>
          </b-dropdown-text>
        </b-dropdown>
        <!--end: Notifications -->

        <!--begin: My Cart -->
        <router-link
                to="/cart"
                size="sm"
                variant="link"
                toggle-class="topbar-item text-decoration-none"
                no-caret
                right
                no-flip
        >
            <!--      <template v-slot:button-content>-->
<!--            <div class="btn btn-icon  btn-dropdown btn-lg mr-1">-->
<!--&lt;!&ndash;          <span class="svg-icon svg-icon-xl svg-icon-primary">&ndash;&gt;-->
<!--&lt;!&ndash;               <i class="menu-icon fas fa-shopping-cart"><span></span></i>&ndash;&gt;-->

<!--&lt;!&ndash;          </span>&ndash;&gt;-->
<!--            </div>-->
            <!--      </template>-->
            <!--      <b-dropdown-text tag="div" class="min-w-md-350px">-->
            <!--        <KTDropdownMyCart></KTDropdownMyCart>-->
            <!--      </b-dropdown-text>-->
        </router-link>
        <!--end: My Cart -->

        <!--begin: User Bar -->
        <KTQuickUser></KTQuickUser>
        <!--end: User Bar -->
    </div>
    <!-- end:: Header Topbar -->
</template>

<style lang="scss">
    .topbar {
        .dropdown-toggle {
            padding: 0;

            &:hover {
                text-decoration: none;
            }

            &.dropdown-toggle-no-caret {
                &:after {
                    content: none;
                }
            }
        }

        .dropdown-menu {
            margin: 0;
            padding: 0;
            outline: none;

            .b-dropdown-text {
                padding: 0;
            }
        }
    }
</style>

<script>
    import KTSearchDefault from "@/view/layout/extras/dropdown/SearchDefault.vue";
    import KTDropdownMyCart from "@/view/layout/extras/dropdown/DropdownMyCart.vue";
    import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
    import i18nService from "@/core/services/i18n.service.js";
    import KTDropdownNotification from "@/view/layout/extras/dropdown/DropdownNotification.vue";
    import ConfigService from "@/core/services/config/ConfigService";
    import NotificationService from "@/core/services/notification/NotificationService";

    const configService = new ConfigService();
    const notificationService = new NotificationService();

    export default {
        name: "KTTopbar",
        data() {
            return {
                languageFlag: "",
                languages: i18nService.languages,
                notificationsCount: 0,
            };
        },
        components: {
            KTDropdownNotification,
            KTSearchDefault,
            // KTDropdownMyCart,
            KTQuickUser,
        },
        mounted() {

        },
      updated() {
        this.initialNotificationCount();
      },
      methods: {
          isAccessible(roles) {
            let flag = [];
            let type = this.currentUser.type;
            let userRoles = configService.getRoles(type);
            if(roles!=null && roles!=undefined && userRoles!=null && userRoles!=undefined){
              roles = roles.split(",");
              for (let t of roles) {
                t = t.trim();
                if (userRoles.includes(t)) {
                  flag.push(true);
                } else {
                  flag.push(false);
                }
              }
              if (flag.includes(true)) {
                return true;
              }
            }
            return false;
          },
            callNotification() {
                this.$bus.emit('checkNotification');
                this.initialNotificationCount();
            },
            onLanguageChanged() {
                this.languageFlag = this.languages.find(val => {
                    return val.lang === i18nService.getActiveLanguage();
                }).flag;
            },
          initialNotificationCount(){

            notificationService
                .countNotificationByUser(this.currentUser.id)
                .then((response) => {
                  this.notificationsCount = response.data.count;

                })
                .catch((err) => {

                });

            setInterval(() => {
              notificationService
                  .countNotificationByUser(this.currentUser.id)
                  .then((response) => {
                    this.notificationsCount = response.data.count;

                  })
                  .catch((err) => {

                  });
            }, 300000);
          },
          setFocus(){
            this.$refs['search-focus'].setFocus();
          },
          triggerSearchBox() {
            this.$refs.mySearch.click();
          }
        },
        computed: {
            getLanguageFlag() {
                return this.onLanguageChanged();
            },
            user() {
                return this.$store.getters.currentUser;
            },
            currentUser() {
                return this.$store.getters.currentUser;
            },

          liveNotificationCount: function () {
            this.initialNotificationCount();
            return this.notificationsCount;
          }
        }
    };
</script>
