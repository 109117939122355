<template>
  <ul class="menu-nav">

    <!--    User-->
    <li
        aria-haspopup="true"
        data-menu-toggle="click"
        class="menu-item menu-item-submenu menu-item-open-dropdown"
        v-bind:class="{ 'menu-item-active': hasActiveChildren('/custom') }"
        v-if="checkIsAccessible('user', 'list') || checkIsAccessible('staff', 'list') || checkIsAccessible('address-book', 'list')
            || checkIsAccessible('user-group', 'list') || checkIsAccessible('alumnus', 'list')"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text"> Users </span>
      </a>
      <div class="menu-submenu menu-submenu-fixed">
        <ul class="menu-subnav">

          <router-link v-if="checkIsAccessible('user', 'list')"
                       to="/students"
                       v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon fa-solid fa-users "><span></span></i>
                <span class="menu-text"> Students </span>
              </a>
            </li>
          </router-link>

          <router-link v-if="checkIsAccessible('user', 'list')"
                       to="/teachers"
                       v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon fas fa-user-tie"><span></span></i>
                <span class="menu-text"> Teachers </span>
              </a>
            </li>
          </router-link>

          <router-link v-if="checkIsAccessible('staff', 'list')"
             to="/user/admin"
             v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon fa fa-user-shield"><span></span></i>
                <span class="menu-text"> Staffs </span>
              </a>
            </li>
          </router-link>

          <router-link v-if="checkIsAccessible('address-book', 'list')"
              :to="{ name: 'address-book' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link">
                <i class="menu-icon fa-solid fa-address-card"></i>
                <span class="menu-text">Contacts</span>
              </a>
            </li>
          </router-link>

          <router-link v-if="checkIsAccessible('user-group', 'list')"
              to="/user-group"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                class="menu-item"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon fa fa-layer-group"><span></span></i>
                <span class="menu-text">  {{groupName }} </span>
              </a>
            </li>
          </router-link>

          <router-link v-if="checkIsAccessible('user', 'list')"
                       to="/graduated-students"
                       v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon fas fa-graduation-cap"><span></span></i>
                <span class="menu-text"> Graduates </span>
              </a>
            </li>
          </router-link>

          <router-link  v-if="checkIsAccessible('alumnus', 'list')"
                        :to="{ name: 'alumnus' }"
                       v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon fas fa-user-graduate"><span></span></i>
                <span class="menu-text"> Alumnus </span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    <!--    User-->
    <!--    Academic -->
    <li
        aria-haspopup="true"
        data-menu-toggle="click"
        class="menu-item menu-item-submenu menu-item-open-dropdown"
        v-bind:class="{ 'menu-item-active': hasActiveChildren('/custom') }"
        v-if="checkIsAccessible('academic-class', 'list') || checkIsAccessible('exam', 'list') || checkIsAccessible('program', 'list')
        || checkIsAccessible('scholarship', 'list')"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text"> Academics </span>
      </a>
      <div class="menu-submenu menu-submenu-fixed">
        <ul class="menu-subnav">

          <router-link v-if="checkIsAccessible('academic-class', 'list')"
                       :to="{name: 'academic-class'}"
                       v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon fas fa-chalkboard-teacher"><span></span></i>
                <span class="menu-text"> Classrooms </span>
              </a>
            </li>
          </router-link>

          <router-link v-if="checkIsAccessible('exam', 'list')"
              :to="{name:'exams'}"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon fa-regular fa-newspaper"><span></span></i>
                <span class="menu-text"> Exams </span>
              </a>
            </li>
          </router-link>

          <router-link v-if="checkIsAccessible('program', 'list')"
                       to="/program"
                       v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon fas fa-person-chalkboard"><span></span></i>
                <span class="menu-text"> Programs </span>
              </a>
            </li>
          </router-link>

          <router-link v-if="checkIsAccessible('academic-class', 'list')"
                       :to="{name: 'graduation-academic-class'}"
                       v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon fas fa-graduation-cap"><span></span></i>
                <span class="menu-text">Graduate students </span>
              </a>
            </li>
          </router-link>

          <router-link v-if="checkIsAccessible('scholarship', 'list')"
              :to="{name: 'scholarship'}"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon fas fa-award"><span></span></i>
                <span class="menu-text">Scholarship </span>
              </a>
            </li>
          </router-link>

          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item menu-item-submenu"
              v-bind:class="{
              'menu-item-active': hasActiveChildren('/custom-wizard/wizard') }"
              v-if="checkIsAccessible('academic-calendar', 'list') || checkIsAccessible('academic-event', 'list')"
          >
            <a href="#" class="menu-link menu-toggle">
              <i class="menu-icon fa fa-calendar"></i>
              <span class="menu-text"> Calendar </span>
              <i class="menu-arrow"></i>
            </a>
            <div class="menu-submenu menu-submenu-fixed menu-submenu-right">
              <ul class="menu-subnav">

                <router-link  v-if="checkIsAccessible('academic-calendar', 'list')"
                              :to="{name: 'academic-calendar'}"
                             v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item menu-item-submenu"
                      :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                  >
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-calendar-check"></i>
                      <span class="menu-text">Program</span>
                    </a>
                  </li>
                </router-link>

                <router-link  v-if="checkIsAccessible('academic-event', 'list')"
                              :to="{name: 'academic-event'}"
                             v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item menu-item-submenu"
                      :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                  >
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-calendar-alt"></i>
                      <span class="menu-text">Events</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </li>
    <!--    Elearing-->
    <li
        aria-haspopup="true"
        data-menu-toggle="click"
        class="menu-item menu-item-submenu menu-item-open-dropdown"
        v-bind:class="{ 'menu-item-active': hasActiveChildren('/vue-bootstrap') }"
        v-if="checkIsAccessible('online-class-room', 'list') || checkIsAccessible('course', 'list') || checkIsAccessible('attendance', 'list')||
              checkIsAccessible('lesson-manger', 'list') || checkIsAccessible('assignment', 'list') || checkIsAccessible('question-bank', 'list') ||
              checkIsAccessible('quiz', 'list')"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text"> E-learning</span>
      </a>
      <div class="menu-submenu menu-submenu-fixed">
        <div class="menu-subnav " style="max-width: 1000px;">
          <ul class="menu-content">
            <li class="menu-item">
              <ul class="menu-inner">
                <router-link v-if="checkIsAccessible('online-class-room', 'list')"
                    :to="{name:'online-class-rooms'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                                  isActive && 'menu-item-active',
                                  isExactActive && 'menu-item-active'
                                ]"
                  >
                    <a :href="href" class="menu-link menu-icon-dot">
                      <i class="menu-icon fas fa-laptop-house"><span></span></i>
                      <span class="menu-text">Online classrooms</span>
                    </a>
                  </li>
                </router-link>

                <router-link v-if="checkIsAccessible('course', 'list')"
                             :to="{name:'courses'}"
                             v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                                  isActive && 'menu-item-active',
                                  isExactActive && 'menu-item-active'
                                ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-book-open"><span></span></i>
                      <span class="menu-text"> Courses </span>
                    </a>
                  </li>
                </router-link>

                <router-link v-if="checkIsAccessible('attendance', 'list')"
                             :to="{name:'class-attendance'}"
                             v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active'
                      ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon far fa-calendar-check"><span></span></i>
                      <span class="menu-text"> Attendance </span>
                    </a>
                  </li>
                </router-link>

                <router-link v-if="checkIsAccessible('lesson-manger', 'list')"
                             :to="{name:'lesson-planner'}"
                             v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                                        isActive && 'menu-item-active',
                                        isExactActive && 'menu-item-active'
                                      ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas flaticon-list-2"><span></span></i>
                      <span class="menu-text"> Lesson planner
                                </span>
                    </a>
                  </li>
                </router-link>

                <router-link v-if="checkIsAccessible('assignment', 'list')"
                             :to="{name: 'assignment'}"
                             v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                                          isActive && 'menu-item-active',
                                          isExactActive && 'menu-item-active'
                                        ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon flaticon-list-1"><span></span></i>
                      <span class="menu-text"> Assignment manager </span>
                    </a>
                  </li>
                </router-link>

                <router-link v-if="checkIsAccessible('question-bank', 'list')"
                    :to="{name:'question-bank'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                                  isActive && 'menu-item-active',
                                  isExactActive && 'menu-item-active'
                                ]"
                  >
                    <a :href="href" class="menu-link menu-icon-dot">
                      <i class="menu-icon fas fa-piggy-bank"><span></span></i>
                      <span class="menu-text">Question bank</span>
                    </a>
                  </li>
                </router-link>

                <router-link v-if="checkIsAccessible('quiz', 'list')"
                    :to="{name:'quiz'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                                  isActive && 'menu-item-active',
                                  isExactActive && 'menu-item-active'
                                ]"
                  >
                    <a :href="href" class="menu-link menu-icon-dot">
                      <i class="menu-icon fas fa-question-circle  "><span></span></i>
                      <span class="menu-text">Quiz</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </li>

          </ul>
        </div>
      </div>
    </li>

    <!--    finance-->
    <li
        aria-haspopup="true"
        data-menu-toggle="click"
        class="menu-item menu-item-submenu menu-item-open-dropdown"
        v-bind:class="{ 'menu-item-active': hasActiveChildren('/custom') }"
        v-if="checkIsAccessible('finance', 'list')"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text"> Finance </span>
      </a>
      <div class="menu-submenu menu-submenu-fixed">
        <ul class="menu-subnav">
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item menu-item-submenu"
              v-bind:class="{
              'menu-item-active': hasActiveChildren('/custom-wizard/wizard') }"
              v-if="checkIsAccessible('finance', 'list') || checkIsAccessible('finance-history-type', 'list') || checkIsAccessible('financial-transaction-head', 'list') || checkIsAccessible('financial-payment-type', 'list')"
          >
            <a href="#" class="menu-link menu-toggle">
              <i class="menu-icon fa fa-cogs"></i>
              <span class="menu-text"> Transactions </span>
              <i class="menu-arrow"></i>
            </a>
            <div class="menu-submenu menu-submenu-fixed menu-submenu-right">
              <ul class="menu-subnav">

                <router-link v-if="checkIsAccessible('fee', 'list')"
                             :to="{name:'fees'}"
                             v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      class="menu-item "
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active'
                      ]"
                  >
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-dollar"></i>
                      <span class="menu-text">Fees</span>
                    </a>
                  </li>
                </router-link>


                <router-link v-if="checkIsAccessible('finance', 'list')"
                             :to="{name:'finance-history'}"
                             v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      class="menu-item "
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active'
                      ]"
                  >
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-file-invoice-dollar"></i>
                      <span class="menu-text">All</span>
                    </a>
                  </li>
                </router-link>

                <router-link v-if="checkIsAccessible('finance', 'list')"
                             :to="{name:'finance-history-status', params: {status: 'pending'}}"
                             v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      class="menu-item "
                      :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                  >
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-file-invoice"></i>
                      <span class="menu-text">Pending</span>
                    </a>
                  </li>
                </router-link>

              </ul>
            </div>
          </li>

          <router-link v-if="checkIsAccessible('financial-transaction-head', 'list')"
                       :to="{ name: 'finance-transaction-head' }"
                       v-slot="{ href, navigate, isActive, isExactActive }">
            <li
                aria-haspopup="true"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon fas fa-money-check"><span></span></i>
                <span class="menu-text"> Transaction headings </span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <!--    finance-->
    <!--    CMS-->
    <li
            aria-haspopup="true"
            data-menu-toggle="click"
            class="menu-item menu-item-submenu menu-item-open-dropdown"
            v-bind:class="{ 'menu-item-active': hasActiveChildren('/custom') }"
            v-if="checkIsAccessible('cms', 'list')"
    >
        <a href="#" class="menu-link menu-toggle">
            <span class="menu-text"> CMS </span>
        </a>
        <div class="menu-submenu menu-submenu-fixed">
            <ul class="menu-subnav">
                <li
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                        class="menu-item menu-item-submenu"
                        v-bind:class="{
                        'menu-item-active': hasActiveChildren('/custom-wizard/wizard') }"
                        v-if="checkIsAccessible('news', 'list') || checkIsAccessible('events', 'list') || checkIsAccessible('blog', 'list')"
                >
                    <a href="#" class="menu-link menu-toggle">
                        <i class="menu-icon fa fa-newspaper"></i>
                        <span class="menu-text"> News & Blogs </span>
                        <i class="menu-arrow"></i>
                    </a>
                    <div class="menu-submenu menu-submenu-fixed menu-submenu-right">
                        <ul class="menu-subnav">
                            <router-link v-if="checkIsAccessible('news', 'list')"
                                         :to="{name:'blogs-list', params:{type:'news'}}"
                                         v-slot="{ href, navigate, isActive, isExactActive }"
                            >
                                <li
                                        aria-haspopup="true"

                                        class="menu-item "
                                        :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
                                >
                                    <a :href="href" class="menu-link">
                                        <i class="menu-icon flaticon2-open-text-book"></i>
                                        <span class="menu-text">News</span>
                                    </a>
                                </li>
                            </router-link>
                            <router-link v-if="checkIsAccessible('events', 'list')"
                                         :to="{name:'blogs-list', params:{type:'event'}}"
                                         v-slot="{ href, navigate, isActive, isExactActive }"
                            >
                                <li
                                        aria-haspopup="true"

                                        class="menu-item "
                                        :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
                                >
                                    <a :href="href" class="menu-link">
                                        <i class="menu-icon flaticon2-calendar-5"></i>
                                        <span class="menu-text">Events</span>
                                    </a>
                                </li>
                            </router-link>
                            <router-link v-if="checkIsAccessible('blog', 'list')"
                                         :to="{name:'blogs-list', params:{type:'blog'}}"
                                         v-slot="{ href, navigate, isActive, isExactActive }"
                            >
                                <li
                                        aria-haspopup="true"

                                        class="menu-item "
                                        :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
                                >
                                    <a :href="href" class="menu-link">
                                        <i class="menu-icon flaticon2-list-2"></i>
                                        <span class="menu-text">Blogs</span>
                                    </a>
                                </li>
                            </router-link>
                        </ul>
                    </div>
                </li>
                <router-link v-if="checkIsAccessible('popup', 'list')"
                             :to="{name:'popup'}"
                             v-slot="{ href, navigate, isActive, isExactActive }"
                >
                    <li
                            aria-haspopup="true"

                            class="menu-item "
                            :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
                    >
                        <a :href="href" class="menu-link">
                            <i class="menu-icon flaticon2-cube-1"></i>
                            <span class="menu-text">Popups</span>
                        </a>
                    </li>
                </router-link>
                <router-link v-if="checkIsAccessible('download', 'list')"
                             :to="{name:'download'}"
                             v-slot="{ href, navigate, isActive, isExactActive }"
                >
                    <li
                            aria-haspopup="true"

                            class="menu-item "
                            :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
                    >
                        <a :href="href" class="menu-link">
                            <i class="menu-icon flaticon2-download"></i>
                            <span class="menu-text">Downloads</span>
                        </a>
                    </li>
                </router-link>
                <li
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                        class="menu-item menu-item-submenu"
                        v-bind:class="{
                        'menu-item-active': hasActiveChildren('/custom-wizard/wizard') }"
                        v-if="checkIsAccessible('album', 'list') || checkIsAccessible('video-gallery', 'list') || checkIsAccessible('media', 'list')"
                >
                    <a href="#" class="menu-link menu-toggle">
                        <i class="menu-icon fa fa-images"></i>
                        <span class="menu-text"> Media </span>
                        <i class="menu-arrow"></i>
                    </a>
                    <div class="menu-submenu menu-submenu-fixed menu-submenu-right">
                        <ul class="menu-subnav">
                            <router-link v-if="checkIsAccessible('album', 'list')"
                                         :to="{name:'album-list'}"
                                         v-slot="{ href, navigate, isActive, isExactActive }"
                            >
                                <li
                                        aria-haspopup="true"

                                        class="menu-item "
                                        :class="[
                                  isActive && 'menu-item-active',
                                  isExactActive && 'menu-item-active'
                                ]"
                                >
                                    <a :href="href" class="menu-link">
                                        <i class="menu-icon flaticon2-image-file"></i>
                                        <span class="menu-text">Gallery</span>
                                    </a>
                                </li>
                            </router-link>
                            <router-link v-if="checkIsAccessible('video-gallery', 'list')"
                                         :to="{name:'video-gallery'}"
                                         v-slot="{ href, navigate, isActive, isExactActive }"
                            >
                                <li
                                        aria-haspopup="true"

                                        class="menu-item "
                                        :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
                                >
                                    <a :href="href" class="menu-link">
                                        <i class="menu-icon fas fa-video"></i>
                                        <span class="menu-text">Video gallery</span>
                                    </a>
                                </li>
                            </router-link>
                            <router-link v-if="checkIsAccessible('media', 'list')"
                                         :to="{name:'media'}"
                                         v-slot="{ href, navigate, isActive, isExactActive }"
                            >
                                <li
                                        aria-haspopup="true"

                                        class="menu-item "
                                        :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
                                >
                                    <a :href="href" class="menu-link">
                                        <i class="menu-icon flaticon2-google-drive-file"></i>
                                        <span class="menu-text">Media library</span>
                                    </a>
                                </li>
                            </router-link>
                        </ul>
                    </div>
                </li>
                <li
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                        class="menu-item menu-item-submenu"
                        v-bind:class="{
                        'menu-item-active': hasActiveChildren('/custom-wizard/wizard') }"
                        v-if="checkIsAccessible('page', 'list')"
                >
                    <a href="#" class="menu-link menu-toggle">
                        <i class="menu-icon fa fa-sticky-note"></i>
                        <span class="menu-text"> Pages </span>
                        <i class="menu-arrow"></i>
                    </a>
                    <div class="menu-submenu menu-submenu-fixed menu-submenu-right">
                        <ul class="menu-subnav">
                            <router-link v-if="checkIsAccessible('page', 'list')"
                                         :to="{name:'page'}"
                                         v-slot="{ href, navigate, isActive, isExactActive }"
                            >
                                <li aria-haspopup="true" class="menu-item "
                                    :class="[
                                isActive && 'menu-item-active',
                                isExactActive && 'menu-item-active'
                              ]"
                                >
                                    <a :href="href" class="menu-link">
                                        <i class="menu-icon flaticon2-copy"></i>
                                        <span class="menu-text">Pages</span>
                                    </a>
                                </li>
                            </router-link>
                            <router-link v-if="checkIsAccessible('page', 'list')"
                                         :to="{name:'home-page'}"
                                         v-slot="{ href, navigate, isActive, isExactActive }"
                            >
                                <li
                                        aria-haspopup="true"

                                        class="menu-item "
                                        :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
                                >
                                    <a :href="href" class="menu-link">
                                        <i class="menu-icon flaticon2-copy"></i>
                                        <span class="menu-text">Home page</span>
                                    </a>
                                </li>
                            </router-link>
                        </ul>
                    </div>
                </li>

                <router-link v-if="checkIsAccessible('slider', 'list')"
                             :to="{name:'slider'}"
                             v-slot="{ href, navigate, isActive, isExactActive }"
                >
                    <li
                            aria-haspopup="true"

                            class="menu-item "
                            :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
                    >
                        <a :href="href" class="menu-link">
                            <i class="menu-icon flaticon-mark"></i>
                            <span class="menu-text">Sliders</span>
                        </a>
                    </li>
                </router-link>

                <router-link v-if="checkIsAccessible('faq', 'list')"
                             :to="{name:'faq'}"
                             v-slot="{ href, navigate, isActive, isExactActive }"
                >
                    <li
                            aria-haspopup="true"

                            class="menu-item "
                            :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
                    >
                        <a :href="href" class="menu-link">
                            <i class="menu-icon fas fa-question-circle"></i>
                            <span class="menu-text">FAQs</span>
                        </a>
                    </li>
                </router-link>

                <router-link v-if="checkIsAccessible('menu-manager', 'list')"
                             :to="{name:'menu-manager'}"
                             v-slot="{ href, navigate, isActive, isExactActive }"
                >
                    <li
                            aria-haspopup="true"

                            class="menu-item "
                            :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
                    >
                        <a :href="href" class="menu-link">
                            <i class="menu-icon fas fa-bars"></i>
                            <span class="menu-text">Menu </span>
                        </a>
                    </li>
                </router-link>
                <li
                        aria-haspopup="true"
                        data-menu-toggle="hover"
                        class="menu-item menu-item-submenu"
                        v-bind:class="{
                        'menu-item-active': hasActiveChildren('/custom-wizard/wizard') }"
                        v-if="checkIsAccessible('faculty-member', 'list')"
                >
                    <a href="#" class="menu-link menu-toggle">
                        <i class="menu-icon fa fa-users"></i>
                        <span class="menu-text"> Team </span>
                        <i class="menu-arrow"></i>
                    </a>
                    <div class="menu-submenu menu-submenu-fixed menu-submenu-right">
                        <ul class="menu-subnav">
                            <router-link v-if="checkIsAccessible('faculty-member', 'list')"
                                         :to="{name:'our-people'}"
                                         v-slot="{ href, navigate, isActive, isExactActive }"
                            >
                                <li
                                        aria-haspopup="true"
                                        class="menu-item "
                                        :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
                                >
                                    <a :href="href" class="menu-link">
                                        <i class="menu-icon fas fa-user-tie"></i>
                                        <span class="menu-text">Members categories</span>
                                    </a>
                                </li>
                            </router-link>

                            <router-link v-if="checkIsAccessible('faculty-member', 'list')"
                                         :to="{name:'members'}"
                                         v-slot="{ href, navigate, isActive, isExactActive }"
                            >
                                <li
                                        aria-haspopup="true"

                                        class="menu-item "
                                        :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
                                >
                                    <a :href="href" class="menu-link">
                                        <i class="menu-icon fas fa-user-tie"></i>
                                        <span class="menu-text">Faculty members</span>
                                    </a>
                                </li>
                            </router-link>
                        </ul>
                    </div>
                </li>
                <router-link v-if="checkIsAccessible('testimonial', 'list')"
                             :to="{name:'testimonial'}"
                             v-slot="{ href, navigate, isActive, isExactActive }"
                >
                    <li
                            aria-haspopup="true"

                            class="menu-item "
                            :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
                    >
                        <a :href="href" class="menu-link">
                            <i class="menu-icon fas fa-people-arrows"></i>
                            <span class="menu-text">Testimonials</span>
                        </a>
                    </li>
                </router-link>

                <router-link v-if="checkIsAccessible('career', 'list')"
                             :to="{name:'career'}"
                             v-slot="{ href, navigate, isActive, isExactActive }"
                >
                    <li
                            aria-haspopup="true"

                            class="menu-item "
                            :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
                    >
                        <a :href="href" class="menu-link">
                            <i class="menu-icon fas fa-briefcase"></i>
                            <span class="menu-text">Careers</span>
                        </a>
                    </li>
                </router-link>
            </ul>
        </div>
    </li>
    <!--    CMS-->
    <!--    settings-->
    <li
        aria-haspopup="true"
        data-menu-toggle="click"
        class="menu-item menu-item-submenu menu-item-open-dropdown"
        v-bind:class="{ 'menu-item-active': hasActiveChildren('/custom') }"
        v-if="checkIsAccessible('setting', 'list')"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text"> Settings </span>
      </a>
      <div class="menu-submenu menu-submenu-fixed">
        <ul class="menu-subnav">
          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item menu-item-submenu"
              v-bind:class="{
              'menu-item-active': hasActiveChildren('/custom-wizard/wizard') }"
              v-if="checkIsAccessible('site-setting', 'list') || checkIsAccessible('printer', 'list') || checkIsAccessible('rooms', 'list')"
          >
            <a href="#" class="menu-link menu-toggle">
              <i class="menu-icon fa fa-cogs"></i>
              <span class="menu-text"> Settings </span>
              <i class="menu-arrow"></i>
            </a>
            <div class="menu-submenu menu-submenu-fixed menu-submenu-right">
              <ul class="menu-subnav">

                <router-link v-if="checkIsAccessible('site-setting', 'list')"
                    :to="{name:'setting'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      class="menu-item "
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active'
                      ]"
                  >
                    <a :href="href" class="menu-link">
                      <i class="menu-icon flaticon-settings"></i>
                      <span class="menu-text">General</span>
                    </a>
                  </li>
                </router-link>

                <router-link v-if="checkIsAccessible('admission-setting', 'list')"
                              :to="{name:'admission-setting'}"
                              v-slot="{ href, navigate, isActive, isExactActive }"
                >
                    <li
                            class="menu-item "
                            :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                    >
                        <a :href="href" class="menu-link">
                            <i class="menu-icon fa fa-paper-plane"></i>
                            <span class="menu-text">Admission setting</span>
                        </a>
                    </li>
                </router-link>

                <router-link v-if="checkIsAccessible('parents-meet-setting', 'list')"
                              :to="{name:'parents-meeting-setting'}"
                              v-slot="{ href, navigate, isActive, isExactActive }"
                >
                    <li
                            class="menu-item "
                            :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                    >
                        <a :href="href" class="menu-link">
                            <i class="menu-icon flaticon-settings"></i>
                            <span class="menu-text">Parents meet setting</span>
                        </a>
                    </li>
                </router-link>

                <router-link v-if="checkIsAccessible('gate-pass-invitation-setting', 'list')"
                              :to="{name:'gate-pass-invitation-setting'}"
                              v-slot="{ href, navigate, isActive, isExactActive }"
                >
                    <li
                            class="menu-item "
                            :class="[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ]"
                    >
                        <a :href="href" class="menu-link">
                            <i class="menu-icon flaticon-settings"></i>
                            <span class="menu-text">Gate pass invitation setting</span>
                        </a>
                    </li>
                </router-link>

                <router-link v-if="checkIsAccessible('printer', 'list')"
                    :to="{name:'printer'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      class="menu-item "
                      :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
                  >
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-print"></i>
                      <span class="menu-text">Printer</span>
                    </a>
                  </li>
                </router-link>

                <router-link v-if="checkIsAccessible('rooms', 'list')"
                    :to="{name:'rooms'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      class="menu-item "
                      :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
                  >
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-building"></i>
                      <span class="menu-text">Manage rooms</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </div>
          </li>

          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item menu-item-submenu"
              v-bind:class="{
              'menu-item-active': hasActiveChildren('/custom-plugins') }"
              v-if="checkIsAccessible('email-template', 'list') || checkIsAccessible('predefined-response', 'list') || checkIsAccessible('push-notification', 'list')"
          >
            <a href="#" class="menu-link menu-toggle">
              <i class="menu-icon fa fa-bell"></i>
              <span class="menu-text"> Notifications </span>
              <i class="menu-arrow"></i>
            </a>
            <div class="menu-submenu menu-submenu-fixed menu-submenu-right">
              <ul class="menu-subnav">

                <router-link v-if="checkIsAccessible('email-template', 'list')"
                    :to="{name:'email-template-roles'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"

                      class="menu-item "
                      :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
                  >
                    <a :href="href" class="menu-link">
                      <i class="menu-icon flaticon2-mail-1"></i>
                      <span class="menu-text">Email templates</span>
                    </a>
                  </li>
                </router-link>

                <router-link v-if="checkIsAccessible('predefined-response', 'list')"
                    :to="{name:'predefined-response'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item menu-item-submenu"
                      :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                  >
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fa fa-envelope"></i>
                      <span class="menu-text">Predefined response</span>
                    </a>
                  </li>
                </router-link>

                <router-link v-if="checkIsAccessible('push-notification', 'list')"
                    :to="{name:'notification-topic'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"

                      class="menu-item "
                      :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
                  >
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fa fa-concierge-bell"></i>
                      <span class="menu-text">Push notification groups</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </div>
          </li>

          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item menu-item-submenu"
              v-bind:class="{
              'menu-item-active': hasActiveChildren('/custom-pages') }"
              v-if="checkIsAccessible('program', 'result-certificate') || checkIsAccessible('template', 'list') || checkIsAccessible('character-certificate', 'list')"
          >
            <a href="#" class="menu-link menu-toggle">
              <i class="menu-icon far fa-image"></i>
              <span class="menu-text">Templates</span>
              <i class="menu-arrow"></i>
            </a>
            <div class="menu-submenu menu-submenu-fixed menu-submenu-right">
              <ul class="menu-subnav">
                <router-link v-if="checkIsAccessible('program', 'result-certificate')"
                    :to="{name:'result-certificate'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      class="menu-item "
                      :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
                  >
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fa-solid fa-certificate"></i>
                      <span class="menu-text">Result certificates</span>
                    </a>
                  </li>
                </router-link>

                <router-link v-if="checkIsAccessible('template', 'list')"
                    :to="{name:'id-card-template'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      class="menu-item "
                      :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
                  >
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fa-regular fa-id-card"></i>
                      <span class="menu-text">ID cards</span>
                    </a>
                  </li>
                </router-link>

                <router-link v-if="checkIsAccessible('character-certificate', 'list')"
                    :to="{name:'character-certificate'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      class="menu-item "
                      :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
                  >
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fa-solid fa-id-card-clip"></i>
                      <span class="menu-text">Character certificate</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </div>
          </li>

          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item menu-item-submenu"
              v-bind:class="{
              'menu-item-active': hasActiveChildren('/custom-pages') }"
              v-if="checkIsAccessible('department', 'list') || checkIsAccessible('institution', 'list') || checkIsAccessible('education', 'list') ||
                    checkIsAccessible('academic-year', 'list') || checkIsAccessible('grade-point', 'list')"
          >
            <a href="#" class="menu-link menu-toggle">
              <i class="menu-icon fa fa-chalkboard"></i>
              <span class="menu-text">Academics</span>
              <i class="menu-arrow"></i>
            </a>
            <div class="menu-submenu menu-submenu-fixed menu-submenu-right">
              <ul class="menu-subnav">
                <router-link v-if="checkIsAccessible('department', 'list')"
                             :to="{name:'department'}"
                             v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                                  isActive && 'menu-item-active',
                                  isExactActive && 'menu-item-active'
                                ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon flaticon2-browser-2"><span></span></i>
                      <span class="menu-text"> Departments </span>
                    </a>
                  </li>
                </router-link>

                <router-link v-if="checkIsAccessible('institution', 'list')"
                    :to="{name:'institution'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                                  isActive && 'menu-item-active',
                                  isExactActive && 'menu-item-active'
                                ]"
                  >
                    <a :href="href" class="menu-link menu-icon-dot">
                      <i class="menu-icon fas fa-hotel"><span></span></i>
                      <span class="menu-text">Institutions</span>
                    </a>
                  </li>
                </router-link>

                <router-link v-if="checkIsAccessible('level', 'list')"
                    :to="{name:'level'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                                  isActive && 'menu-item-active',
                                  isExactActive && 'menu-item-active'
                                ]"
                  >
                    <a :href="href" class="menu-link menu-icon-dot">
                      <i class="menu-icon fas fa-laptop-house"><span></span></i>
                      <span class="menu-text">Education levels</span>
                    </a>
                  </li>
                </router-link>

<!--                <router-link v-if="checkIsAccessible('education', 'list')"-->
<!--                    :to="{name:'education'}"-->
<!--                    v-slot="{ href, navigate, isActive, isExactActive }"-->
<!--                >-->
<!--                  <li-->
<!--                      aria-haspopup="true"-->
<!--                      class="menu-item"-->
<!--                      :class="[-->
<!--                                  isActive && 'menu-item-active',-->
<!--                                  isExactActive && 'menu-item-active'-->
<!--                                ]"-->
<!--                  >-->
<!--                    <a :href="href" class="menu-link menu-icon-dot">-->
<!--                      <i class="menu-icon fas fa-laptop-house"><span></span></i>-->
<!--                      <span class="menu-text">Education levels</span>-->
<!--                    </a>-->
<!--                  </li>-->
<!--                </router-link>-->

                <router-link v-if="checkIsAccessible('academic-year', 'list')"
                             :to="{name:'academic-year'}"
                             v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active'
                      ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon far fa-calendar-alt"><span></span></i>
                      <span class="menu-text"> Academic year (Batch) </span>
                    </a>
                  </li>
                </router-link>

                <router-link v-if="checkIsAccessible('grade-point', 'list')"
                             :to="{name:'exams-grade-point'}"
                             v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active'
                      ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon far fa-file-excel"><span></span></i>
                      <span class="menu-text"> Exam grading points </span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </div>
          </li>

          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item menu-item-submenu"
              v-if="checkIsAccessible('user', 'list') || checkIsAccessible('role', 'list') || checkIsAccessible('zoom-user', 'list')"
          >
            <a href="#" class="menu-link menu-toggle">
              <i class="menu-icon fa fa-mobile-alt"></i>
              <span class="menu-text"> Devices & security </span>
              <i class="menu-arrow"></i>
            </a>
            <div class="menu-submenu menu-submenu-fixed menu-submenu-right">
              <ul class="menu-subnav">
                <router-link v-if="checkIsAccessible('user', 'list')"
                             :to="{name:'kiosk_devices'}"
                             v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                                          isActive && 'menu-item-active',
                                          isExactActive && 'menu-item-active'
                                        ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-tablet-alt"><span></span></i>
                      <span class="menu-text"> KIOSK devices </span>
                    </a>
                  </li>
                </router-link>

                <router-link  v-if="checkIsAccessible('role', 'list')"
                    :to="{ name: 'role', params: { type: 'admin' } }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                                                isActive && 'menu-item-active',
                                                isExactActive && 'menu-item-active'
                                              ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-user-shield"><span></span></i>
                      <span class="menu-text"> Roles & permissions </span>
                    </a>
                  </li>
                </router-link>

                <router-link v-if="checkIsAccessible('zoom-user', 'list')"
                    :to="{ name: 'zoom-user', params: { type: 'admin' } }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active'
                      ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-video"><span></span></i>
                      <span class="menu-text"> Zoom account sync </span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </div>
          </li>

          <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item menu-item-submenu"
              v-if="checkIsAccessible('leave-category', 'list') || checkIsAccessible('visitor-category', 'list') || checkIsAccessible('inventory-category', 'list')"
          >
            <a href="#" class="menu-link menu-toggle">
              <i class="menu-icon flaticon2-list-2"></i>
              <span class="menu-text"> Categories </span>
              <i class="menu-arrow"></i>
            </a>
            <div class="menu-submenu menu-submenu-fixed menu-submenu-right">
              <ul class="menu-subnav">
                <router-link  v-if="checkIsAccessible('leave-category', 'list')"
                    :to="{ name: 'leave-category', params: { type: 'admin' } }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active'
                      ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-umbrella-beach"><span></span></i>
                      <span class="menu-text"> Leave </span>
                    </a>
                  </li>
                </router-link>
                <router-link  v-if="checkIsAccessible('general-request-category', 'list')"
                    :to="{ name: 'general-request-category' }"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active'
                      ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas fa-bars"><span></span></i>
                      <span class="menu-text"> General request </span>
                    </a>
                  </li>
                </router-link>

                <router-link v-if="checkIsAccessible('visitor-category', 'list')"
                             :to="{ name: 'visitor-category', params: { type: 'admin' } }"
                             v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      aria-haspopup="true"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active'
                      ]"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <i class="menu-icon fas  fa-users"><span></span></i>
                      <span class="menu-text"> Visitors </span>
                    </a>
                  </li>
                </router-link>

                <router-link v-if="checkIsAccessible('inventory-category', 'list')"
                    :to="{name:'category'}"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                      class="menu-item "
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active'
                      ]"
                  >
                    <a :href="href" class="menu-link">
                      <i class="menu-icon fas fa-database"></i>
                      <span class="menu-text">Inventory</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </li>
    <!--    settings-->
    <!--    support-->
    <li
        aria-haspopup="true"
        data-menu-toggle="click"
        class="menu-item menu-item-submenu menu-item-open-dropdown"
        v-bind:class="{ 'menu-item-active': hasActiveChildren('/custom') }"
        v-if="checkIsAccessible('parent-meet', 'list') || checkIsAccessible('leave-request', 'list') || checkIsAccessible('alumnus', 'pending-approval')"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text"> Tasks </span>
        <span class="badge badge-primary">{{ tasksMenuBadgeCount }}</span>
      </a>
      <div class="menu-submenu menu-submenu-fixed">
        <ul class="menu-subnav">
          <router-link v-if="checkIsAccessible('parent-meet', 'list')"
              :to="{name: 'student-parents-visit-logs'}"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                class="menu-item"
                :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active'
                      ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon fa-regular fa-calendar-days"><span></span></i>
                <span class="menu-text">Parents meet </span>
                <span class="badge badge-primary">
                {{ requestedParentMeetsCount }}
                </span>
              </a>
            </li>
          </router-link>

          <router-link v-if="checkIsAccessible('leave-request', 'list')"
              :to="{ name: 'leave', params: { type: 'admin' } }"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link">
                <i class="menu-icon fas fa-umbrella-beach"></i>
                <span class="menu-text">Leave requests</span>
                <span class="badge badge-primary">
                {{ pendingLeaveRequestCount }}
                </span>
              </a>
            </li>
          </router-link>

          <router-link v-if="checkIsAccessible('alumnus', 'approval')"
                       :to="{ name: 'academic-graduated-program' }"
                       v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link">
                <i class="menu-icon fas fa-user-friends"></i>
                <span class="menu-text">Alumni approval</span>
                <span class="badge badge-primary">
                {{ alumnusGraduatedProgramCount }}
                </span>
              </a>
            </li>
          </router-link>

          <router-link v-if="checkIsAccessible('gate-passes', 'list')"
              :to="{name: 'pending-gate-passes'}"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                class="menu-item"
                :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active'
                      ]"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon fas fa-spinner"><span></span></i>
                <span class="menu-text">Pending gate pass </span>
                <span class="badge badge-primary">
                {{ pendingGatePassCount }}
                </span>
              </a>
            </li>
          </router-link>

        </ul>
      </div>
    </li>
    <!--    support-->
  </ul>
</template>

<script>
import ConfigService from "../../../core/services/config/ConfigService";
import StudentParentsVisitLogsService from "@/core/services/student-parents-visit-logs/StudentParentsVisitLogsService";
import LeaveRequestService from "@/core/services/leave/leave-request/LeaveRequestService";
import GatePassService from "@/core/services/gate-pass/GatePassService";
import AlumnusGraduatedProgramService from "@/core/services/alumnus/AlumnusGraduatedProgramService";

const configService = new ConfigService();
const parentMeet = new StudentParentsVisitLogsService();
const leaveRequest = new LeaveRequestService();
const gatePass = new GatePassService();
const alumnusGraduatedProgram=new AlumnusGraduatedProgramService();

export default {
  name: "KTMenu",
  data() {
    return {
      roles: null,
      alumnusGraduatedProgramCount: 0,
      pendingGatePassCount: 0,
      pendingLeaveRequestCount: 0,
      requestedParentMeetsCount: 0
    }
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    isAccessible(roles) {
      let flag = [];
      let type = this.currentUser.type;
      let userRoles = configService.getRoles(type);
      if (roles != null && roles != undefined && userRoles != null && userRoles != undefined) {
        roles = roles.split(",");
        for (let t of roles) {
          t = t.trim();
          if (userRoles.includes(t)) {
            flag.push(true);
          } else {
            flag.push(false);
          }
        }
        if (flag.includes(true)) {
          return true;
        }
      }
      return false;
    },
    getParentMeetCount(){
      parentMeet
      .getRequestedMeetingCount()
      .then((res) => {
        this.requestedParentMeetsCount = res.data.requestedParentMeetsCount ? res.data.requestedParentMeetsCount : '0';
      })
      .catch(err => {

      });
    },
    getPendingLeaveRequestCount(){
      leaveRequest
      .getPendingLeaveRequestCounts()
      .then((res) => {
        this.pendingLeaveRequestCount = res.data.pendingLeaveRequestCount ? res.data.pendingLeaveRequestCount : '0';
      })
      .catch(err => {

      });
    },
    getPendingGatePassCount(){
      gatePass
      .getPendingGatePassCounts()
      .then((res) => {
        this.pendingGatePassCount = res.data.pendingGatePassCount ? res.data.pendingGatePassCount : '0';
      })
      .catch(err => {

      });
    },
    getPendingAlumnusCount(){
      alumnusGraduatedProgram
      .getPendingAlumnusCount()
      .then((res) => {
        this.alumnusGraduatedProgramCount = res.data.alumnusGraduatedProgramCount ? res.data.alumnusGraduatedProgramCount : '0';
      })
      .catch(err => {

      });
    },
  },
  mounted(){
    this.getParentMeetCount();
    this.getPendingLeaveRequestCount();
    this.getPendingGatePassCount();
    this.getPendingAlumnusCount();
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    isAdmin() {
      return this.currentUser.type === "admin" ? true : false;
    },
    tasksMenuBadgeCount(){
      return(parseInt(this.alumnusGraduatedProgramCount)+parseInt(this.pendingGatePassCount)+parseInt(this.pendingLeaveRequestCount)+parseInt(this.requestedParentMeetsCount));
    }
  }
};
</script>
