<template>
    <v-dialog
            v-model="loader"
            persistent
            width="400"
    >
        <v-card
                color="primary"
                dark
        >
            <v-card-text class="text-center">
               <span class="text-subtitle-2">Please wait your request is being processed.</span>
                <v-progress-linear
                        indeterminate
                        color="white"
                        class="mb-0 mt-1"
                ></v-progress-linear>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'Loading',
        data: () => ({
            loader: false,
        }),
        methods: {
            show() {
                this.loader = true;
            },
            hide(){
                this.loader = false;
            }
        },
        created () {
            this.$bus.on('toggleLoader', ()=>{
                this.loader =!this.loader;
            })
        },
    }
</script>
