<template>
  <ul class="menu-nav">
    <router-link :to="{name:'dashboard'}" v-slot="{ href, navigate, isActive, isExactActive }" title="Dashboard" >
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']">
        <a :href="href" class="menu-link" @click="navigate"  title="Printing">
          <i class="menu-icon fas fa-home"><span></span></i>
          <span class="menu-text">Dashboard</span>
        </a>
      </li>
    </router-link>
    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/vue-bootstrap') }"
        v-if="checkIsAccessible('pre-registration', 'list') || checkIsAccessible('entrance-exam', 'list')"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fa fa-file-contract"></i>
        <span class="menu-text">Admissions</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link v-if="checkIsAccessible('pre-registration', 'list')"
              :to="{ name: 'pre-registration-list' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                  aria-haspopup="true"
                  class="menu-item"
                  :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-icon fas fa-id-badge"></i>
                <span class="menu-text">Applications</span>
              </a>
            </li>
          </router-link>

          <router-link v-if="checkIsAccessible('entrance-exam', 'list')"
              :to="{name:'entrance-exam'}"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
            >
              <a :href="href" class="menu-link menu-icon-dot">
                <i class="menu-icon fa fa-file"><span></span></i>
                <span class="menu-text">Entrance exams</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/vue-bootstrap') }"
        v-if="checkIsAccessible('attendance', 'list') || checkIsAccessible('gate-passes', 'list')"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fa fa-calendar-check"></i>
        <span class="menu-text">Attendances</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link v-if="checkIsAccessible('attendance', 'list')"
              :to="{name:'general-attendance'}"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                class="menu-item "
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-icon fas fa-school"></i>
                <span class="menu-text">Check-in/out </span>
              </a>
            </li>
          </router-link>

          <router-link v-if="checkIsAccessible('gate-passes', 'list')"
              :to="{name:'gate-passes'}"
               v-slot="{ href, navigate, isActive, isExactActive }">
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-icon fas fa-file-pdf"></i>
                <span class="menu-text">Gate pass</span>
              </a>
            </li>
          </router-link>

        </ul>
      </div>
    </li>

    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/vue-bootstrap') }"
        v-if="checkIsAccessible('bulk-notification', 'list') || checkIsAccessible('push-notification', 'list')"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fa fa-bell"></i>
        <span class="menu-text">Notifications</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link v-if="checkIsAccessible('bulk-notification', 'list')"
              :to="{ name: 'bulk-notification' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link">
                <i class="menu-icon fas fa-mail-bulk"></i>
                <span class="menu-text">Bulk email/SMS</span>
              </a>
            </li>
          </router-link>

          <router-link v-if="checkIsAccessible('push-notification', 'list')"
              :to="{name:'notification'}"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link">
                <i class="menu-icon fa fa-bullhorn"></i>
                <span class="menu-text">Push notifications</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/library') }"
        v-if="checkIsAccessible('library-book', 'list') || checkIsAccessible('library-issue', 'list')"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fas fa-book"></i>
        <span class="menu-text">Library</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link v-if="checkIsAccessible('library-book', 'list')"
                       :to="{name:'library-dashboard'}"
                       v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon fas fa-home"><span></span></i>
                <span class="menu-text">Library dashboard </span>
              </a>
            </li>
          </router-link>

          <router-link v-if="checkIsAccessible('library-book', 'list')" :to="{name:'all-books'}" v-slot="{ href, navigate, isActive, isExactActive }" >
            <li
                aria-haspopup="true"
                class="menu-item"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-icon fas fa-book"><span></span></i>
                <span class="menu-text"> All books </span>
              </a>
            </li>
          </router-link>

          <router-link v-if="checkIsAccessible('library-book', 'list')"
                       :to="{name:'lost-book-list'}"
                       v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-icon fas fa-book"></i>
                <span class="menu-text">All lost books</span>
              </a>
            </li>
          </router-link>

          <router-link v-if="checkIsAccessible('library-issue', 'list')"
                       :to="{name:'issued-list'}"
                       v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-icon fas fa-arrow-circle-left"></i>
                <span class="menu-text">Books issued</span>
              </a>
            </li>
          </router-link>

          <router-link v-if="checkIsAccessible('library-issue', 'list')"
              :to="{name:'returned-list'}"
              v-slot="{ href, navigate, isActive, isExactActive }">
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-icon fas fa-arrow-circle-right"></i>
                <span class="menu-text">Books returned</span>
              </a>
            </li>
          </router-link>

          <router-link v-if="checkIsAccessible('library-issue', 'list')"
             :to="{name:'not-returned-book'}"
             v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu"
                :class="[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-icon fas fa-arrow-circle-down"></i>
                <span class="menu-text">Books not returned</span>
              </a>
            </li>
          </router-link>

        </ul>
      </div>
    </li>

    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/vue-bootstrap') }"
        v-if="checkIsAccessible('contact-us', 'list') || checkIsAccessible('visitor', 'list')"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-digital-marketing"></i>
        <span class="menu-text">
          Leads
          <span class="badge badge-primary">{{ parseInt(checkedInVisitorsCount)+parseInt(unreadContactsCount) }}</span>
        </span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link v-if="checkIsAccessible('contact-us', 'list')"
              :to="{ name: 'messages' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link">
                <i class="menu-icon flaticon2-mail-1"></i>
                <span class="menu-text">Online inquiries
                  <span class="badge badge-primary">{{ unreadContactsCount }}</span>
                </span>
              </a>
            </li>
          </router-link>

          <router-link v-if="checkIsAccessible('visitor', 'list')"
              :to="{ name: 'visitor' }"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item menu-item-submenu"
                :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link">
                <i class="menu-icon fa fa-users"></i>
                <span class="menu-text">
                  Visitors
                  <span class="badge badge-primary">{{ checkedInVisitorsCount }}</span>
                </span>
              </a>
            </li>
          </router-link>

        </ul>
      </div>
    </li>

    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/vue-bootstrap') }"
        v-if="checkIsAccessible('report', 'list') || checkIsAccessible('attendance-report', 'list')"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fa fa-chart-bar"></i>
        <span class="menu-text">Reports</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link v-if="checkIsAccessible('attendance-report', 'list')"
              :to="{name:'attendance-report'}"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                class="menu-item "
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-icon flaticon-settings"></i>
                <span class="menu-text">Attendances </span>
              </a>
            </li>
          </router-link>

          <router-link  v-if="checkIsAccessible('program', 'list')"
              :to="{name:'program-report'}"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                class="menu-item "
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-icon fas fa-newspaper"></i>
                <span class="menu-text">Programs</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/vue-bootstrap') }"
        v-if="checkIsAccessible('inventory-item', 'list') || checkIsAccessible('inventory-item-stock', 'list') || checkIsAccessible('inventory-stock-register', 'list')"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fa fa-boxes"></i>
        <span class="menu-text">Inventory</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
              v-if="checkIsAccessible('inventory-item', 'list')"
              :to="{name:'inventory-item'}"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                class="menu-item "
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-icon fas fa-box"></i>
                <span class="menu-text">Stocks </span>
              </a>
            </li>
          </router-link>

          <router-link
              v-if="checkIsAccessible('inventory-item-stock', 'list')"
              :to="{name:'inventory-item-stock'}"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                class="menu-item "
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-icon fas fa-truck-moving"></i>
                <span class="menu-text">Stock intake </span>
              </a>
            </li>
          </router-link>

          <router-link
              v-if="checkIsAccessible('inventory-stock-register', 'list')"
              :to="{name:'inventory-assigned-list'}"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                class="menu-item "
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-icon fas fa-boxes"></i>
                <span class="menu-text">Stock register </span>
              </a>
            </li>
          </router-link>

        </ul>
      </div>
    </li>

<!--    <li-->
<!--        aria-haspopup="true"-->
<!--        data-menu-toggle="hover"-->
<!--        class="menu-item menu-item-submenu"-->
<!--        v-bind:class="{ 'menu-item-open': hasActiveChildren('/vue-bootstrap') }"-->
<!--        v-if="checkIsAccessible('hostel', 'list') || checkIsAccessible('hostel-building', 'list') || checkIsAccessible('hostel-room', 'list') || checkIsAccessible('hostel-resident', 'list')">-->
<!--      <a href="#" class="menu-link menu-toggle">-->
<!--        <i class="menu-icon fa fa-hot-tub"></i>-->
<!--        <span class="menu-text">Hostel</span>-->
<!--        <i class="menu-arrow"></i>-->
<!--      </a>-->
<!--      <div class="menu-submenu">-->
<!--        <span class="menu-arrow"></span>-->
<!--        <ul class="menu-subnav">-->
<!--          <router-link v-if="checkIsAccessible('hostel', 'list')"-->
<!--              :to="{name:'hostel'}"-->
<!--              v-slot="{ href, navigate, isActive, isExactActive }"-->
<!--          >-->
<!--            <li-->
<!--                class="menu-item "-->
<!--                :class="[-->
<!--                  isActive && 'menu-item-active',-->
<!--                  isExactActive && 'menu-item-active'-->
<!--                ]"-->
<!--            >-->
<!--              <a :href="href" class="menu-link">-->
<!--                <i class="menu-icon fas fa-bed"></i>-->
<!--                <span class="menu-text">Hostel </span>-->
<!--              </a>-->
<!--            </li>-->
<!--          </router-link>-->

<!--          <router-link v-if="checkIsAccessible('hostel-building', 'list')"-->
<!--              :to="{name:'hostel-building'}"-->
<!--              v-slot="{ href, navigate, isActive, isExactActive }"-->
<!--          >-->
<!--            <li-->
<!--                class="menu-item "-->
<!--                :class="[-->
<!--                  isActive && 'menu-item-active',-->
<!--                  isExactActive && 'menu-item-active'-->
<!--                ]"-->
<!--            >-->
<!--              <a :href="href" class="menu-link">-->
<!--                <i class="menu-icon fas fa-building"></i>-->
<!--                <span class="menu-text">Hostel building </span>-->
<!--              </a>-->
<!--            </li>-->
<!--          </router-link>-->

<!--          <router-link v-if="checkIsAccessible('hostel-room', 'list')"-->
<!--              :to="{name:'hostel-room'}"-->
<!--              v-slot="{ href, navigate, isActive, isExactActive }"-->
<!--          >-->
<!--            <li-->
<!--                class="menu-item "-->
<!--                :class="[-->
<!--                  isActive && 'menu-item-active',-->
<!--                  isExactActive && 'menu-item-active'-->
<!--                ]"-->
<!--            >-->
<!--              <a :href="href" class="menu-link">-->
<!--                <i class="menu-icon fas fa-building"></i>-->
<!--                <span class="menu-text">Hostel room </span>-->
<!--              </a>-->
<!--            </li>-->
<!--          </router-link>-->

<!--          <router-link v-if="checkIsAccessible('hostel-resident', 'list')"-->
<!--              :to="{name:'hostel-resident'}"-->
<!--              v-slot="{ href, navigate, isActive, isExactActive }"-->
<!--          >-->
<!--            <li-->
<!--                class="menu-item "-->
<!--                :class="[-->
<!--                  isActive && 'menu-item-active',-->
<!--                  isExactActive && 'menu-item-active'-->
<!--                ]"-->
<!--            >-->
<!--              <a :href="href" class="menu-link">-->
<!--                <i class="menu-icon fas fa-users"></i>-->
<!--                <span class="menu-text">Hostel resident</span>-->
<!--              </a>-->
<!--            </li>-->
<!--          </router-link>-->

<!--        </ul>-->
<!--      </div>-->
<!--    </li>-->

    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/vue-bootstrap') }"
        v-if="checkIsAccessible('transport', 'list')"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fa fa-car"></i>
        <span class="menu-text">Transport</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link v-if="checkIsAccessible('route', 'list')"
              :to="{name:'route'}"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                class="menu-item "
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-icon fa fa-map-marked"></i>
                <span class="menu-text">Routes </span>
              </a>
            </li>
          </router-link>

          <router-link v-if="checkIsAccessible('vehicle', 'list')"
              :to="{name:'vehicle'}"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                class="menu-item "
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-icon fas fa-car"></i>
                <span class="menu-text">Vehicles</span>
              </a>
            </li>
          </router-link>

          <router-link v-if="checkIsAccessible('transport-allocation', 'list')"
              :to="{name:'transport-allocation'}"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                class="menu-item "
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-icon fas fa-map-marker"></i>
                <span class="menu-text">Transport allocation</span>
              </a>
            </li>
          </router-link>

          <router-link v-if="checkIsAccessible('vehicles-drives', 'list')"
              :to="{name:'vehicles-drives'}"
              v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
                aria-haspopup="true"
                class="menu-item "
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
            >
              <a :href="href" class="menu-link">
                <i class="menu-icon fas fa-mail-bulk"></i>
                <span class="menu-text">Vehicles drivers</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>

    <router-link :to="{name:'printing'}" v-slot="{ href, navigate, isActive, isExactActive }" title="Printing" v-if="checkIsAccessible('printing','list')">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']">
        <a :href="href" class="menu-link" @click="navigate"  title="Printing">
          <i class="menu-icon fas fa-print"><span></span></i>
          <span class="menu-text">Printing</span>
        </a>
      </li>
    </router-link>

    <router-link :to="{name:'rfid-cards'}" v-slot="{ href, navigate, isActive, isExactActive }" title="RFID cards" v-if="checkIsAccessible('rfid-card','list')">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item menu-item-submenu" :class="[isActive && 'menu-item-active',isExactActive && 'menu-item-active']">
        <a :href="href" class="menu-link" @click="navigate"  title="Printing">
          <i class="menu-icon fas fa-clock"><span></span></i>
          <span class="menu-text">RFID cards</span>
        </a>
      </li>
    </router-link>

    <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{ 'menu-item-open': hasActiveChildren('/vue-bootstrap') }"
        v-if="checkIsAccessible('general-request', 'list')"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon fa fa-bars"></i>
        <span class="menu-text">General request</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <template v-if="checkIsAccessible('general-request', 'list')">
            <router-link
                         v-slot="{ href, navigate, isActive, isExactActive }"
                         v-for="(category, index) in generalRequestCategories" :key="index"
                         :to="{name:'general-request', params: {type: category.value}}"
            >
              <li
                  class="menu-item "
                  :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link">
                  <i class="menu-icon fas" v-bind:class="category.icon"></i>
                  <span class="menu-text">{{ category.name }} </span>
                </a>
              </li>
            </router-link>
          </template>

        </ul>
      </div>
    </li>
  </ul>
</template>
<script>
import ConfigService from "@/core/services/config/ConfigService";
import ContactService from "@/core/services/contact/ContactService";
import VisitorService from "@/core/services/visitor/visitor/VisitorService";
import GeneralRequestCategoryService from "@/core/services/general-request/category/GeneralRequestCategoryService";

const configService = new ConfigService();
const contact = new ContactService();
const visitor = new VisitorService();
const generalRequestCategory = new GeneralRequestCategoryService();

export default {
  name: "KTMenu",
  data(){
    return{
      unreadContactsCount: 0,
      checkedInVisitorsCount: 0,
      generalRequestCategories: [],
    }
  },
  mounted(){
    this.getUnreadContacts();
    this.getCheckedInVisitorCount();
    this.getGeneralRequestCategory();
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },

    getGeneralRequestCategory() {
      generalRequestCategory.getAllRequestCategoryMenu().then((response) => {
        this.generalRequestCategories = response.data.data;
      }).catch(() => {})
    },

    isAccessible(roles) {
      let flag = [];
      let type = this.currentUser.type;
      let userRoles = configService.getRoles(type);
      if (roles != null && roles != undefined && userRoles != null && userRoles != undefined) {
        roles = roles.split(",");
        for (let t of roles) {
          t = t.trim();
          if (userRoles.includes(t)) {
            flag.push(true);
          } else {
            flag.push(false);
          }
        }
        if (flag.includes(true)) {
          return true;
        }
      }
      return false;
    },

    getUnreadContacts(){
      contact
      .getUnreadContacts()
      .then((res) => {
        this.unreadContactsCount = res.data.unreadContactsCount ? res.data.unreadContactsCount : '0';
      })
    },

    getCheckedInVisitorCount(){
      visitor
      .getCheckedInVisitorCount()
      .then((res) => {
        this.checkedInVisitorsCount = res.data.checkedInVisitorsCount ? res.data.checkedInVisitorsCount : '0';
      })
    },

  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    isAdmin() {
      return this.currentUser.type === "admin" ? true : false;
    },
  },

};
</script>
