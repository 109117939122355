import Apiservice from '@/core/services/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;


export default class AlumnusGraduatedProgramService{
    #api = null;

    constructor() {
        this.#api = `${API_URL}user/alumnus-graduated-program`;
    }
    paginate(data=null, index=1) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url,data);

    }
    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }
    getPendingAlumnusCount(){
        let url = `${this.#api}/pending/count`
        return apiService.get(url);
    }
}